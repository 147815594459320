console.log('Vite ⚡️ Ruby')

import Vue from 'vue'
import Admin from '../views/Admin.vue'
import Rails from 'rails-ujs'
import DashboardPlugin from './material-dashboard.js'
import VeeValidate, { Validator } from'vee-validate'
import ja from 'vee-validate/dist/locale/ja'
import axios from 'axios'
import VueAxios from '../plugins/vue-axios.js'
import Cookies from 'js-cookie';
import router from './router.js'
import VueRouter from 'vue-router'
import store from './store.js'
import format from '../mixins/format.js'
import alert from '../mixins/alert.js'
import constants from '../mixins/constants.js'
import VueFroalaConfig from '../plugins/vue-froala-config.js'
import VueJsDatePickerConfig from '../plugins/vuejs-datepicker-config.js'
import Vue2TransitionsConfig from '../plugins/vue2-transitions-config.js'
import jQuery from 'jquery';
window.jQuery = jQuery;

// Rails.start()
Vue.prototype.$Cookies = Cookies;
Vue.use(VueFroalaConfig)
Vue.use(VueJsDatePickerConfig)
Vue.use(Vue2TransitionsConfig)
Vue.use(DashboardPlugin)
Vue.use(VueAxios, {axios: axios , csrfToken: Rails.csrfToken})
// vee-validateの日本語
Validator.localize('ja', ja);
Vue.use(VeeValidate, { locale: ja , inject: false});
Vue.use(VueRouter)
Vue.mixin(format)
Vue.mixin(alert)
Vue.mixin(constants)

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#app',
    router,
    store,
    render: h => h(Admin)
  })
})