import 'froala-editor/js/froala_editor.pkgd.min';
import 'froala-editor/js/languages/ja'
import 'froala-editor/css/themes/gray.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import VueFroala from 'vue-froala-wysiwyg'

const VueFroalaConfig = {
  install: function(Vue) {
    Vue.use(VueFroala)
    Vue.prototype.config = {
      key: 'yDC5hH4H4B11A8C7F5F6g1JWSDBCQG1ZGDf1C1d2JXDAAOZWJhE5B4E4G3F2H3A10A4C4D4==',
      placeholderText: 'ここを編集してください',
      charCounterCount: true,
      attribution: false,
      toolbarSticky: false,
      toolbarButtons: [
        'fullscreen',
        'bold',
        'italic',
        'underline','|',
        'fontFamily',
        'fontSize',
        'color',
        'lineHeight', '|',
        'paragraphFormat',
        'align',
        'formatOL',
        'formatUL',
        'embedly',
        'insertTable', '|',
        'emoticons',
        'fontAwesome',
        'specialCharacters',
        'insertHR',
        'clearFormatting', '|',
        'spellChecker', 'help', 'html', '|', 'undo', 'redo'],
      quickInsertButtons: ['embedly', 'table', 'ul', 'ol', 'hr'],
      imageResizeWithPercent: true,
      imageDefaultWidth: 100,
      language: 'ja',
      theme: 'gray'
    }
  }
}
export default VueFroalaConfig