import swal from 'sweetalert2'
import router from '../entrypoints/router.js'

export default {
  methods: {
    tokenError() {
      swal.fire({
        title: `アクセストークンの期限が切れました。<br>再度ログインしてください。`,
        buttonsStyling: false,
        icon: 'warning',
        customClass: {
          confirmButton: "md-button md-success"
        },
      }).then (function () {
        router.push({ path: '/admin/login' })
      })
    },
    dangerNotify(message){
      this.$notify({
        message: message,
        icon: 'add_alarm',
        horizontalAlign: 'left',
        verticalAlign: 'top',
        icon: 'danger'
      })
    },
    successNotify(message){
      this.$notify({
        message: message,
        icon: 'add_alarm',
        horizontalAlign: 'left',
        verticalAlign: 'top',
        icon: 'success'
      })
    },
    validateError(text=''){
      swal.fire({
        title: `入力項目に不備があります。<br>再度ご確認お願いいたします。`,
        text: text,
        buttonsStyling: false,
        icon: 'warning',
        customClass: {
          confirmButton: "md-button md-success"
        },
      })
    },
    successAndBackPage(message, page=''){
      swal.fire({
        title: message,
        buttonsStyling: false,
        icon: 'success',
        customClass: {
          confirmButton: "md-button md-success"
        },
      }).then(response => {
        if (this.newFlag) {
          router.push({ path: page })
        }
      })
    },
    noSearchResults(){
      this.warningMessage(`検索結果がありませんでした`)
    },
    warningMessage(message){
      swal.fire({
        title: message,
        buttonsStyling: false,
        icon: 'warning',
        customClass: {
          confirmButton: "md-button md-success"
        },
      })
    }
  }
}
